.snowcanvas {
  position: relative;

  background-color: var(--color-background);
  transition: background-color 300ms;

  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  overflow: hidden;
  touch-action: none;

  border-bottom: 1px solid var(--color-stroke);
}

.snowcanvas .snowcanvas__title {
  transition: opacity 1000ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.snowcanvas .snowcanvas__surface {
  --padding: 50%;

  position: absolute;
  top: 0;
  left: 0;

  width: 300%;
  height: 300%;

  transform: translate(-25%, -25%);

  overflow: hidden;

  /* background-color: rgba(255, 0, 0, 0.1); */
}

.snowcanvas .snowcanvas__item {
  --standard-width: 20rem;
  --extra-hover-width: 5rem;

  position: absolute;
  top: 0;
  left: 0;
  width: 12rem;
  max-width: 45rem;

  transition: max-width 300ms, margin 300ms, opacity 2000ms;
  user-select: none;
}

body.debug .snowcanvas__item {
  background-color: rgba(255, 0, 0, 0.1);
}

.snowcanvas .snowcanvas__item.third {
  width: calc(100vw * (4 / 12));
}

.snowcanvas .snowcanvas__item.fourth {
  width: calc(100vw * (5 / 12));
}

.snowcanvas .snowcanvas__item.fifth {
  width: calc(100vw * (6 / 12));
}

@media screen(m) {
  .snowcanvas .snowcanvas__item.third {
    width: calc(100vw * (3 / 12));
  }

  .snowcanvas .snowcanvas__item.fourth {
    width: calc(100vw * (4 / 12));
  }

  .snowcanvas .snowcanvas__item.fifth {
    width: calc(100vw * (5 / 12));
  }
}

.snowcanvas .snowcanvas__item.faded {
  opacity: 0;
  pointer-events: none;
}

@media screen(m) {
  .snowcanvas .snowcanvas__item {
    /* width: calc(100vw * (3 / 12)); */
  }
}

.snowcanvas .snowcanvas__image {
  width: 100%;
  transform-origin: center bottom;

  background-size: 100% 100%;
  background-repeat: no-repeat;

  transition: transform 600ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform: scale(0.85);
}

.snowcanvas .snowcanvas__item:hover .snowcanvas__image,
.snowcanvas .snowcanvas__item:active .snowcanvas__image {
  transform: scale(1);
}

.snowcanvas .snowcanvas__caption {
  padding-block: 1rem;

  opacity: 0;
  /* padding-top: 1.5rem; */

  transition: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms; /* Delay Out */
}

.snowcanvas .snowcanvas__item:hover .snowcanvas__caption {
  opacity: 1;
  /* padding-top: 1rem; */
  transition-delay: 200ms; /* Delay In */
}
