.globe {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  background-color: var(--color-area-bg);
  /* transition: background-color 800ms; */
}

.globe .globe-viewport,
.globe .globe-content {
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.globe .project-list-container {
  position: absolute;
  width: 100%;
  right: 0;
  height: 100%;
  z-index: 1000;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1.5rem;
  pointer-events: none;
}

@media screen(m) {
  .globe .project-list-container {
    width: fit-content;
  }
}

.globe .project-list {
  --padding: 1rem;
  --vertical-spacing: 0.5rem;

  position: relative;
  display: flex; /*added*/
  flex-direction: column; /*added*/

  padding: var(--padding);

  line-height: 1.4;

  background-color: var(--color-background);
  background-color: color-mix(
    in srgb,
    var(--color-background) 65%,
    transparent
  );
  border-radius: 16px;
  backdrop-filter: blur(20px);

  opacity: 0;
  transition: 200ms;
  pointer-events: none;
  visibility: hidden;

  overflow-y: auto;

  /* Mobile Specific */
  width: 100%;
  transform: translate(0, 1rem);
  max-height: 45%;
  font-size: 14px;
}

@media screen(m) {
  .globe .project-list {
    --padding: 1.5rem;
    --vertical-spacing: 1rem;

    width: auto;
    min-width: 20rem;
    max-width: 24rem;
    transform: translate(1rem, 0);
    max-height: 100%;

    font-size: 16px;
  }
}

.globe .project-list.is-visible {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  pointer-events: all;
}

.globe .project-list__heading {
  margin: 0;
  margin-bottom: var(--vertical-spacing);
  padding-bottom: var(--vertical-spacing);

  color: var(--color-grey-2);

  border-bottom: 1px solid var(--color-stroke);
}

.globe ul.project-list__items {
  --scrollbar-foreground: var(--color-stroke);
  --scrollbar-background: transparent;

  margin: 0;
  padding: 0;
  height: 100%;

  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;

  scroll-snap-type: y proximity;

  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}

.globe .project-list__items::-webkit-scrollbar {
  width: 2px;
  height: 4px;
  border-radius: 2px;
}

.globe .project-list__items::-webkit-scrollbar-thumb {
  background: var(--scrollbar-foreground);
  border-radius: 4px;
  transition: 400ms;
}

.globe .project-list__items::-webkit-scrollbar-track {
  background: var(--scrollbar-background);
  border-radius: 4px;
}

.globe .project-list__item {
  position: relative;

  color: var(--color-grey-1);

  /* border-bottom: 1px solid var(--color-stroke); */
  scroll-snap-align: start;
  transition: 150ms;
}

.globe .project-list__item:not(:last-child) {
  padding-bottom: var(--vertical-spacing);
  margin-bottom: var(--vertical-spacing);
}

.globe .project-list__item:hover {
  opacity: 0.75;
}

.globe .project-list__item:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: var(--color-stroke);
}

.globe .project-list__items.has-scrollbar .project-list__item::after {
  right: 1rem;
}

.globe .project-list__items:not(.has-scrollbar) .project-list__item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.globe .project-list__item a {
  text-decoration: none;
}

.globe .project-list__name {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.globe .project-list__title {
  display: inline-block;
  margin-right: 0.5em;
  color: var(--color-foreground);
  white-space: nowrap;
}

.globe .project-list__sub-title {
  display: inline-block;
  color: var(--color-grey-1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.globe .project-list__location {
  color: var(--color-grey-1);
}

.globe .tooltip-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  pointer-events: none;
}

.globe .tooltip-content {
  width: fit-content;
  background-color: var(--color-background);
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--color-stroke);
  border-radius: 999px;

  font-size: 0.85rem;
  color: var(--color-foreground);

  /* transform: translateY(-50%); */
  white-space: nowrap;

  opacity: 0;
  transition-property: opacity, margin-left, color, background-color,
    border-color;
  transition-duration: 400ms;
}

.globe .tooltip-content.active {
  opacity: 1;
  margin-left: 1rem;
}

.globe-content .globe-outline {
  fill: var(--color-stroke);
  stroke: var(--color-stroke);
  stroke-width: 0;
  transition-property: fill, stroke;
  transition-duration: 400ms;
  pointer-events: all;
}

.globe-content .globe-landmass {
  fill: var(--color-background);
  transition: fill 400ms;
  pointer-events: all;
}

.globe-content .location {
  fill: var(--color-grey-2);
  transition: fill 400ms;
  pointer-events: all;
}

.globe-content .location:hover,
.globe-content .location:active,
.globe-content .location:focus {
  fill: var(--color-grey-1);
}

.globe-content .location.selected {
  fill: var(--color-accent);
}

.globe-content .location.active {
  fill: red;
}

.globe-content .studio {
  fill: var(--color-grey-2);
  opacity: 0.5;
  font-size: 1rem;
  pointer-events: none;
  transition: fill 200ms;
}
