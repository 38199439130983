@layer components {
    .display {
        @apply text-40 leading-1_05 m:text-96 m:leading-1;
    }

    .headline {
        @apply text-24 leading-1_2 m:text-48 m:leading-1_3;
    }

    .subheading {
        @apply text-20 leading-1_45 m:text-32 m:leading-1_4;
    }

    .body {
        @apply text-18 leading-1_4 m:text-22 m:leading-1_45;
    }

    .technical {
        @apply text-16 leading-1_4 m:text-18 m:leading-1_4;
    }

    .technical-small {
        @apply text-14 leading-1_4 m:text-16 m:leading-1_4;
    }


    .toggle {
        cursor: pointer;

        input[type="checkbox"] {
            appearance: none;
            width: 0;
            height: 0;
            display: inline;
        }

        input[type="checkbox"]:focus {
            outline: 0;

            + .toggle__inner {
                outline: 2px solid currentColor;
                outline-offset: 3px;
            }
        }

        &__bullet {
            right: 0;
            transition-property: border-color;
        }

        &__bullet-bg {
            top: -1px;
            left: -1px;
            transition-property: width;
        }

        &__inner {
            user-select: none;
        }
        
        &__indicator-on,
        &__indicator-off {
            min-width: 28px;
        }
        &__indicator-on {
            display: none;
        }

        &__indicator-off {
            display: inline;
        }

        input[type="checkbox"]:checked + .toggle__inner {
            @apply text-foreground;

            .toggle__bullet {
            //transform: translateX(14px);
            }

            .toggle__bullet-bg {
                width: 25px;
                
                @screen m {
                    width: 30px;
                }
            //left: -1px
            }
            
            .toggle__indicator-on {
                display: inline;
            }
    
            .toggle__indicator-off {
                display: none;
            }
            
        }
    }

    .plus-icon {
        span:last-child {
            @apply transition-all duration-150 ease-in-out-sine;

            [aria-expanded="true"] & {
                transform: scaleY(0);
            }
        }
    }

    .filter-panel {
        h3 button[aria-expanded="true"] {
            color: currentColor;
            border-color: currentColor;

            svg {
                transform: rotate(180deg) translateY(1px);
            }
        }
    }

    .radio-w-icon {
        cursor: pointer;

        input[type="radio"] {
            appearance: none;
            width: 0;
            height: 0;
            display: inline;
        }

        input[type="radio"]:focus {
            outline: 0;

            + .radio-w-icon__inner {
                outline: 2px solid currentColor;
                outline-offset: 1px;
            }
        }

        &__inner {
            user-select: none;
        }

        input[type="radio"]:checked + .radio-w-icon__inner {
            @apply text-foreground;
        }
    }

    .radio,
    .checkbox {
        input {
            appearance: none;
            width: 0;
            height: 0;
            display: block;
        }

        input:focus {
            outline: 0;

            + .radio__inner,
            + .checkbox__inner {
                outline: 2px solid currentColor;
                outline-offset: 1px;
            }
        }

        &__inner {
            user-select: none;
        }
    }

    .checkbox {
        &__bullet:before {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            left: 0;
            top: 0;
            @apply bg-current;
            opacity: 0;
        }

        input[type="checkbox"]:checked + .checkbox__inner {
            @apply text-foreground;

            .checkbox__bullet:before {
                opacity: 1;
            }
        }
    }

    .radio {
        &__bullet:before {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            left: 0;
            top: 0;
            @apply bg-current;
            opacity: 0;
        }

        input[type="radio"]:checked + .radio__inner {
            @apply text-foreground;

            .radio__bullet:before {
                opacity: 1;
            }
        }
    }

    .fade-list {
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                color: var(--color-stroke);

                .text-foreground {
                    color: var(--color-stroke);
                }

                .text-grey-1 {
                    color: var(--color-stroke);
                }

                .text-grey-2 {
                    color: var(--color-stroke);
                }
            }

            a:hover {
                color: var(--color-foreground);

                * {
                    color: var(--color-foreground);
                }

                .text-foreground {
                    color: var(--color-foreground);
                }

                .text-grey-1 {
                    color: var(--color-grey-1);
                }

                .text-grey-2 {
                    color: var(--color-grey-2);
                }
            }
        }
    }

    .accordion-selected-item {
        &:hover {
            background: transparent !important;
        }
        
        & > div {
            color: var(--color-foreground);
        }
    }

    .chapter-list-wrapper {
        a {
            opacity: 0;
        }

        .is-selected a {
            opacity: 1;
        }

        &.is-opened {
            a {
                opacity: 1;
            }
        }
    }
    
    .has-zoom-image {
        html:not(.simplified) & {
            @media (hover: hover) {
                img {
                    transition-property: opacity, transform !important;
                    transition-duration: 600ms, 800ms !important;;
                    transition-timing-function: cubic-bezier(0.390, 0.575, 0.565, 1.000), cubic-bezier(0.165, 0.840, 0.440, 1.000) !important;;
                    
                    @apply origin-center;
                }

                &:hover img {
                    transform: scale(1.05);
                }
            }
        }
    }

}
