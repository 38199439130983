.page-footer {
    &__logo-wrap {
        width: 48px;
        height: 62px;
    }

    &__lottie-logo-wrap {
        width: 142%;
        height: 100%;
        left: -21%;
        top: -5px;

        svg {
            position: absolute;
            left: 0;
            top: 0;
        }
        
        path {
            .dark & {
                stroke: #fff !important;
            }
        }
    }
}
