.wysiwyg {
    p, ul, ol {
        margin-bottom: 24px;
        
        @screen m {
            margin-bottom: 32px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    p {
        text-wrap: pretty;
    }
    
    ul, ol {
        @apply body;
    }
    
    em, i {
        font-style: italic;
    }

    strong, b {
        font-weight: inherit;
    }
    
    ul {

    }
    
    ol {
        counter-reset: item;
    }
    
    li {
        display: list-item;
        position: relative;
        padding: 9px 0 7px 24px;
        
        @apply border-b-1 border-stroke;
        
        @screen m {
            padding: 17px 0 15px 39px;
        }
        
        &:last-child {
            @apply border-0;
        }
    }
    
    ul li:before {
        content: '';
        position: absolute;
        left: 0;
        top: 9px;
        display: block;
        
        @screen m {
            left: 0;
            top: 17px;
        }
    }
    
    ol li:before {
        font-variant-numeric: tabular-nums;
        position: absolute;
        content: counter(item) ".";
        counter-increment: item;
        left: 0;
        top: 9px;
        
        @screen m {
            left: 0;
            top: 17px;
        }
    }
    
    a {
        @apply text-grey-1 border-b-1 border-grey-1 hover:text-grey-2 hover:border-stroke transition-colors duration-300;
    }

    &--image-description {
        p {
            margin-bottom: 0;
            display: inline;
        }
    }
    
    &--large-text {
        a {
            @apply border-b-2;
        }
    }
    
    &--inline-intro {
        p {
            margin-bottom: 0;
            display: inline;
        }
    }
}
