html {
    font-feature-settings: 'liga' 1, 'dlig' 1, 'pnum' 1, 'lnum' 1;
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-600 ease-out-sine;
}

img[data-prefs-image="low"] {
    image-rendering: pixelated;
}

html.no-js {
    img.lazyload {
        display: none;
    }
}

html.simplified * {
    transition-duration: 0s !important;
}

fieldset {
    min-inline-size: 100%;
}

:focus {
    outline: 2px solid currentColor;
    outline-offset: 3px;
}

html.outline,
html.no-outline * {
    outline: none !important;
}

::selection {
    background: rgba(0, 0, 0, 0.99);
    color: #FFF;

    .dark & {
        background: rgba(255, 255, 255, 0.99);
        color: #000;
    }
}

:root {
    --color-grey-1: #757575;
    --color-grey-2: #949494;
    --color-stroke: #DFDFDF;
    --color-area-bg: #F3F3F3;
    --color-foreground: #000;
    --color-background: #FFF;
    --color-accent: #42ff00;
}

.dark {
    --color-grey-1: #A3A3A3;
    --color-grey-2: #757575;
    --color-stroke: #404040;
    --color-area-bg: #1E1E1E;
    --color-foreground: #FFF;
    --color-background: #000;
}

[data-content-blocks] > *:first-child {
    margin-top: 0 !important;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}
